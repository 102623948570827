import React from "react";

function App() {
  return (
    <div>
      <h1>Hello and Welcome to vasu's website update coming soon</h1>
    </div>
  );
}

export default App;
